<div class="flex flex-col h-max-screen w-max-screen">  
    <div class="flex flex-col">
        <!-- Tab bar -->
        <div class="flex flex-row shadow-md">
            <div *ngFor="let menu of audiumMenus"
                [routerLink]="menu.link"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-base3"
                class="hover:text-base3 hover:bg-base03 hover:base-03 align-bottom px-2"
            >
            {{ menu.title }}
            </div>
            <!-- Search -->
            <!-- <div class="flex justify-center p-1">
                <div class="content-center border-[1px] border-base1 rounded-full bg-base03 shadow-md">
                    <span class="material-icons align-bottom mx-5">search</span>
                    <input type="text" placeholder="Search..." class="bg-base03 w-content">
                </div>
            </div> -->
        </div>

        <div class="pb-[6em]">
            <router-outlet></router-outlet>
        </div>

        <!-- Now Playing bar -->
        <div class="fixed bottom-0 w-full bg-base02 shadow-md">
            <div class="flex flex-col bg-base02">
                <div [id]="'seekbar'" class="bg-base03 flow-root my-2">
                    <span class="material-icons float-right text-xs text-base2 inline-flex align-middle">radio_button_checked</span>
                </div>
            </div>
            <div class="flex flex-row bg-base02" *ngIf="mpdApi.status">
                <div class="flex w-1/3">
                    <div class="flex flex-row">
                        <div class="w-12 mr-4">
                            <app-audium-cover-art [filePath]="mpdApi.nowPlaying?.artist?.toLowerCase()+'/'+mpdApi.nowPlaying?.album?.toLowerCase()"></app-audium-cover-art>
                        </div>
                        <div class="">
                            {{mpdApi.nowPlaying?.title}} <br>
                            {{mpdApi.nowPlaying?.artist}}
                        </div>
                    </div>
                </div>
                <div class="flex w-1/3 justify-center">
                    <button *ngIf="mpdApi.status.repeat" class="" (click)="mpdApi"><span class="material-icons align-bottom mr-2">repeat</span></button>
                    <button *ngIf="!mpdApi.status.repeat" class=" text-base01" (click)="mpdApi"><span class="material-icons align-bottom mr-2">repeat</span></button>
        
                    <button class="" (click)="mpdApi.previous()"><span class="material-icons align-bottom mr-2">skip_previous</span></button>
        
                    <button *ngIf="mpdApi.status.state == 'pause'" class="" (click)="playPause()"><span class="material-icons align-bottom mr-2">play_circle_filled</span></button>
                    <button *ngIf="mpdApi.status.state == 'play'" class="" (click)="playPause()"><span class="material-icons align-bottom mr-2">pause</span></button>
        
                    <button class="" (click)="mpdApi.next()"><span class="material-icons align-bottom mr-2">skip_next</span></button>
        
                    <button *ngIf="mpdApi.status.random" class="" (click)="mpdApi"><span class="material-icons align-bottom mr-2">shuffle</span></button>
                    <button *ngIf="!mpdApi.status.random" class=" text-base01" (click)="mpdApi"><span class="material-icons align-bottom mr-2">shuffle</span></button>
                </div>
                <div *ngIf="mpdApi?.status?.audio" class="flex flex-row-reverse gap-2 w-1/3 pt-2 align-text-bottom">
                    <div class=""><span class="material-icons inline align-top">volume_up</span>{{ mpdApi.status.volume }}%</div>
                    <div class="">{{ mpdApi.status.audio.bits }}bit {{ mpdApi.status.audio.sample_rate_short.value}}{{ mpdApi.status.audio.sample_rate_short.unit }}</div>
                    <div class="">{{ timeString(mpdApi.status.elapsed) }} / {{ timeString(mpdApi.status.duration) }}</div>
                </div>
            </div>
        </div>
        
    </div>
</div>  
