import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { AudiumComponent, AudiumQueueComponent, AudiumLibraryComponent, AudiumCoverArtComponent } from './audium.component';
import { MpdApiService } from './mpd-api.service';


// import { JournalServiceService } from '../journal/journal-service.service';

@NgModule({
    imports:      [
        AppRoutingModule, CommonModule, BrowserModule ],
    declarations: [ AudiumComponent,
    AudiumLibraryComponent,
    AudiumQueueComponent,
    AudiumCoverArtComponent ],
    exports:      [ ]
  })
  export class AudiumModule {
    constructor() {
    }
  
    static forRoot() {
        return {
          ngModule: AudiumModule,
          providers: [MpdApiService]
        };
      }
  }