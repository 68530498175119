import { Component, Injectable, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import { EditorConfig } from '@editorjs/editorjs';
import codexNotifier from 'codex-notifier';
import codexTooltip from 'codex-tooltip';
import Header from '@editorjs/header'; 
// @ts-ignore
import List from '@editorjs/list'; 
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-texteditor',
  templateUrl: './texteditor.component.html',
  styleUrls: ['./texteditor.component.css']
})
export class TexteditorComponent implements OnDestroy, OnChanges {
  @Input() config: any;
  @Output() noteDataUpdated = new EventEmitter<any>();
    
  public editor: EditorJS | null = null
  public data: any = {}
  private setEditor(config: any) {
    console.log('config',config)
    let options: EditorConfig = {
      holder: config.id,
      data: config.data,
      defaultBlock: 'header',
      minHeight: 100,
      readOnly: config.readOnly,
      placeholder: 'placeholder text',
      inlineToolbar: ['bold', 'italic'],
      tools: { 
        header: Header,
        // header: {
        //   class: Header, 
        //   inlineToolbar: ['link'], 
        // },
        list: List 
      },
      // clear, onReady
      onChange: (api: any, event: any) => {
        this.editor?.save()
        .then(res => {
          this.data = res
          this.noteDataUpdated.emit({...this.config, data: this.data})
        })
      },
    }
    console.log('options',options)
    if (config.id !== '' && config.data) {
      this.editor = new EditorJS(options)
      // console.log(this.editor)
    } else {
      console.log('editorjs init fail', config.id, config.data)
    }
  }

  async ngOnInit() {
    this.setEditor(this.config)
  }

  ngOnDestroy() {
    this.editor?.destroy()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes',changes)
  }

}
