import { Component, Input, OnInit } from '@angular/core';
import { MpdApiService } from './mpd-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-audium-library',
	templateUrl: './library.component.html',
	styleUrls: ['./audium.component.css']
})
export class AudiumLibraryComponent {
	constructor(public mpdApi: MpdApiService) {}
	public selectedArtist: any
	public albumSort = 'desc'
	public loaded = false
	
	public add(artist: string, album: string, date: any) {
		let string = `${artist}/${album} (${this.dateString(date)})`
		this.mpdApi.add(string)
		// this.mpdApi.view = 'queue'
		// this.mpdApi.play({index: 0})
	}

	public dateString(date: string) {
		return date? new Date(date).getFullYear() : ''
	}

	public sortAlbums(albums: any) {
		return this.albumSort == 'asc'? albums.sort((a: any, b: any) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}) : albums
	}
}

@Component({
	selector: 'app-audium-queue',
	templateUrl: './queue.component.html',
	styleUrls: ['./audium.component.css']
})
export class AudiumQueueComponent {
	constructor(public mpdApi: MpdApiService) {}
	public selectedArtist: any
}

@Component({
	selector: 'app-audium-cover-art',
	templateUrl: './cover-art.component.html',
	styleUrls: ['./audium.component.css']
})
export class AudiumCoverArtComponent {
	constructor(public mpdApi: MpdApiService) {this.filePath = ''}
	@Input() filePath: string;

	public srcString: string = '';
	public artFound: boolean = false;

	ngOnInit() {
		if (this.filePath) {
			this.findThumbnail(this.filePath)
		}
	}

	public findThumbnail(key: string) {
		// @ts-ignore
		if (this.mpdApi.thumbnails[key]) {
			// @ts-ignore
			this.srcString = `data:image/jpeg;base64,${this.mpdApi.thumbnails[key]}`
			this.artFound = true;
		} else {
			console.log('bad key', key)
		}
	}

}

@Component({
	selector: 'app-audium',
	templateUrl: './audium.component.html',
	styleUrls: ['./audium.component.css']
})
export class AudiumComponent implements OnInit {
	constructor(public mpdApi: MpdApiService, private route: ActivatedRoute, private router: Router) {}

	public client: any = this.mpdApi.client

	public audiumMenus: any = [{title: 'Queue', link: './queue'}, {title: 'Library', link: './library'}, {title: 'Settings', link: './settings'}]

	public sources = [
		{label: 'Local', icon: 'folder', status: 'text-green', toggle: true},
		{label: 'Cloud', icon: 'cloud', status: 'text-base00', toggle: false},
		{label: 'Spotify', icon: 'play_circle_filled', status: 'text-base00', toggle: false},
	]

	public timeString(time: number | undefined): string {
		if (time !== undefined) {
			let min = Math.floor(time/60)
			let sec = Math.ceil(time-(min*60))
			if (sec < 10) { 
				return `${min}:0${sec}`
			} else {
				return `${min}:${sec}`
			}
		} else {
			return ``
		}
	}

	public playPause() {
		switch(this.mpdApi.status?.state) {
			case 'play':
				this.mpdApi.pause()
				break
			case 'pause':
				this.mpdApi.resume()
				break
		}
	}

	private handleKeydown(event: { key: string; }) {
		switch (event.key) {
			case 'p':
				this.playPause()
				break;
			case '1':
				this.router.navigateByUrl('/audium/queue')
				break;
			case '2':
				this.router.navigateByUrl('/audium/library')
				break;
		}
	}
	
	ngOnInit(): void {
		this.mpdApi.update()
		this.mpdApi.getLibrary()
		this.mpdApi.getQueue()

		window.addEventListener('keydown', this.handleKeydown.bind(this), false)

		if (this.mpdApi.queue?.length == 0) {
			this.router.navigateByUrl('/audium/library')
		} else {
			this.router.navigateByUrl('/audium/queue')
		}
	
	}

	ngOnDestroy(): void {
		clearInterval(this.mpdApi.statusInterval)
		this.mpdApi.statusInterval = null
		window.removeEventListener('keydown', this.handleKeydown, false)
	}

}
