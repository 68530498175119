<div class="p-4">
    
    <!-- new note -->
    <div class="pb-4">
        <div class="flex">
            <!-- <h1 class="pl-2 my-auto">Notepad</h1> -->
            <div class="bg-base02 rounded-md m-4 border-[1px] border-base01 mx-auto">
                <div class="border-b-[1px] border-b-base01 mx-2 py-2">
                    <form [formGroup]="newNoteForm">
                        <input formControlName="title" placeholder="Title" class="font-[400] mx-2 bg-base02 focus:outline-none">
                        <input formControlName="category" placeholder="Category (optional)" class="font-[400] mx-2 bg-base02 focus:outline-none float-right">
                    </form>
                </div>
                <div class="font-[300] mx-4 py-2">
                    <app-texteditor [config]="newNoteConfig" (noteDataUpdated)="setNote($event)" ></app-texteditor>
                </div>
            </div>
            <button (click)="postNote(newNote)">POST</button>
        </div>
    </div>

    <!-- categories -->
    <div class="p-2 m-2 flex border-b-[1px] border-base01 pb-4">
        <span class="bg-base02 rounded-full p-1 pt-0 px-1.5 mx-1 border-[1px] border-base01 hover:border-base2 hover:text-base2 hover:cursor-pointer" *ngFor="let category of categories">{{category}}</span>
    </div>
    
    <!-- note cards -->
    <div class="flex">
        <div class="pb-4 pr-4">
            <div *ngFor="let note of notes" 
            (click)="onClick(note)"
            [class.border-base2]="note == selectedNote"
            class="bg-base02 rounded-md m-4 border-[1px] border-base01">
                <div *ngIf="note.title" class="border-b-[1px] border-b-base01 mx-2 py-2">
                    <p class="font-[400] mx-2">{{note.title}}</p>
                </div>
                <div class="ml-4 text-md">
                    <app-texteditor [config]="note" (noteDataUpdated)="setNote($event)" ></app-texteditor>
                </div>
            </div>
        </div>
    </div>
</div>