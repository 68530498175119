<div class="px-4 text-sm flex flex-col gap-4">
    <div>
        <h3>Stat Priorities</h3>
        <div class="flex flex-wrap gap-2 justify-between">
            <div *ngFor="let item of statPriorities" class="flex-nowrap">
                <div class="flex-col">
                    <span class="capitalize text-base2">{{ item?.slot }}</span>
                    <div *ngFor="let stat of item?.stats">
                        <span [ngClass]="stat.class" class="text-base2">{{ stat.label}}</span>
                    </div>
                    <div *ngIf="item.implicit" class="">
                        {{ item.implicit }}
                    </div>
                    <!-- <div class="text-legendary">
                        {{ item.aspect }}
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div>
        <h3>Equipped</h3>
        <div class="flex flex-wrap gap-2 justify-between">
            <div *ngFor="let item of statPriorities" class="flex-nowrap">
                <div class="flex-col">
                    <span class="capitalize text-base2">{{ item?.slot }}</span>
                    <div *ngFor="let stat of item?.stats">
                        <span [ngClass]="stat.class" class="text-base2">{{ stat.label}}</span>
                    </div>
                    <div *ngIf="item.implicit" class="">
                        {{ item.implicit }}
                    </div>
                    <!-- <div class="text-legendary">
                        {{ item.aspect }}
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div>
        <h3>Bases</h3>
        <div class="flex flex-row gap-2 mb-4">
            <div *ngFor="let item of stash" class="w-1/4">
                <div class="flex-col">
                    <span class="capitalize text-legendary">{{ item.slot }}</span>
                    <div class="">
                        {{ item.ilvl }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <h3>Aspects</h3>
        <div class="flex flex-row gap-2">
            <div *ngFor="let aspect of aspects" class="w-1/4">
                <span class="capitalize text-legendary">{{ aspect.aspect }}</span>
                <div class="flex flex-row justify-between">
                    <span><input [(ngModel)]="aspect.value" class="appearance-none  bg-base02 rounded-md w-[2em] pl-1" />%</span>
                    <span class="italic text-sm">{{ aspect.range }}</span>
                </div>
            </div>
        </div>
    </div>
    
    <div class="flex flex-col">
        <h3>Tools</h3>
        <div>
            Roll: 
        </div>
        <div>
            Roll Max:
        </div>
        <div>
            Compare Max:
        </div>
    </div>
</div>