import { Component } from '@angular/core';

@Component({
  selector: 'app-diablo',
  templateUrl: './diablo.component.html',
  styleUrls: ['./diablo.component.css'],
})
export class DiabloComponent {
  public statPriorities = [
    {
      slot: 'weapon',
      stats: [
        { label: 'Crit Damage', class: 'text-legendary' },
        { label: 'Vuln Damage', class: 'text-legendary' },
        { label: 'Int' },
        { label: 'Core Damage' },
      ],
      aspect: 'Control'
    },
    {
      slot: 'offhand',
      stats: [
        { label: 'Cooldown Reduction', class: 'text-legendary' },
        { label: 'Resource Generation', class: 'text-legendary' },
        { label: 'Mana Cost Reduction' },
        { label: 'Critical Strike Chance' },
      ],
      aspect: 'Conceited'
    },
    {
      slot: 'helm',
      stats: [
        { label: 'Cooldown Reduction', class: 'text-legendary' },
        { label: 'Max Mana', class: 'text-legendary' },
        { label: 'Lucky hit while barrier' },
        { label: '% Total Armor' },
      ],
      aspect: 'Frostblitz'
    },
    {
      slot: 'chest',
      stats: [
        { label: 'DR against Burning', class: 'text-legendary' },
        { label: 'DR against Close', class: 'text-legendary' },
        { label: 'Damage Reduction' },
        { label: 'DR against Distant' },
      ],
      aspect: 'Fortune'
    },
    {
      slot: 'gloves',
      stats: [
        { label: 'Ice Shards', class: 'text-skill' },
        { label: 'Crit Chance', class: 'text-legendary' },
        { label: 'Attack Speed' },
        { label: 'Lucky Hit: Resource' },
      ],
      aspect: 'Piercing Cold'
    },
    {
      slot: 'pants',
      stats: [
        { label: 'DR against Burning', class: 'text-legendary' },
        { label: 'DR against Close', class: 'text-legendary' },
        { label: 'Damage Reduction' },
        { label: 'DR against Distant' },
      ],
      implicit: 'Potion Grants Barrier',
      aspect: 'Disobedience'
    },
    {
      slot: 'boots',
      stats: [
        { label: 'Mana Cost Reduction', class: 'text-legendary' },
        { label: 'Frost Nova', class: 'text-skill' },
        { label: 'Movement Speed' },
        { label: 'Teleport', class: 'text-skill'},
      ],
      implicit: 'Movement Speed after Barrier',
      aspect: 'Fortune'
    },
    {
      slot: 'amulet',
      stats: [
        { label: 'Cooldown Reduction', class: 'text-legendary' },
        { label: 'Mana Cost Reduction', class: 'text-legendary' },
        { label: 'Devouring Blaze', class: 'text-skill' },
        { label: 'Defensive Skills', class: 'text-skill' },
      ],
      aspect: 'Elementalist'
    },
    {
      slot: 'ring',
      stats: [
        { label: 'Crit Damage', class: 'text-legendary' },
        { label: 'Vuln Damage', class: 'text-legendary' },
        { label: 'Resource Generation' },
        { label: 'Crit Chance' },
      ],
      aspect: 'Storm Swell/Prodigy'
    },
  ];

  public newstatPriorities = [
        { label: 'Int' },
        { label: 'Core Damage' },
        { label: 'Max Mana' },
        { label: 'Lucky hit while barrier' },
        { label: '% Total Armor' },
        { label: 'Ice Shards', class: 'text-skill' },
        { label: 'Attack Speed' },
        { label: 'Lucky Hit: Resource' },
        { label: 'DR against Burning' },
        { label: 'DR against Close' },
        { label: 'Damage Reduction' },
        { label: 'DR against Distant' },
        { label: 'Frost Nova', class: 'text-skill' },
        { label: 'Movement Speed' },
        { label: 'Teleport', class: 'text-skill'},
        { label: 'Cooldown Reduction' },
        { label: 'Mana Cost Reduction' },
        { label: 'Devouring Blaze', class: 'text-skill' },
        { label: 'Defensive Skills', class: 'text-skill' },
        { label: 'Crit Damage' },
        { label: 'Vuln Damage' },
        { label: 'Resource Generation' },
        { label: 'Crit Chance' },
  ];

  public aspects = [
    {slot: 'weapon', aspect: 'Control', value: 1, range: '25% - 35%'},
    {slot: 'offhand', aspect: 'Conceited', value: 1, range: '15% - 25%'},
    {slot: 'helm', aspect: 'Frostblitz', value: 1, range: '40% - 30%'},
    {slot: 'gloves', aspect: 'Piercing Cold', value: 1, range: '25% - 20%'},
    {slot: 'pants', aspect: 'Disobedience', value: 1, range: '0.25% - 0.50%'},
    {slot: 'boots', aspect: 'Fortune', value: 1, range: '10% - 20%'},
    {slot: 'amulet', aspect: 'Elementalist', value: 1, range: '30% - 60%'}, // double due to amulet
    {slot: 'ring1', aspect: 'Prodigy', value: 1, range: '15-25'},
    {slot: 'ring2', aspect: 'Storm Swell', value: 1, range: '11% - 20%'}
  ]

  public stash = [
    {slot: 'weapon', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'offhand', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'helm', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'chest', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'gloves', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'pants', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'boots', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'amulet', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'ring1', ilvl: 1, stats: [{stat: '', value: 1}]},
    {slot: 'ring2', ilvl: 1, stats: [{stat: '', value: 1}]}
  ]

}


