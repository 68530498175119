<div class="">
    <table class="w-100 whitespace-nowrap">
        <tr class="font-bold">
            <td></td>
            <td>Title</td>
            <td>Artist</td>
            <td>Album</td>
            <td class="w-full"><span (click)="mpdApi.clear()" class="material-icons cursor-grab float-right">clear</span></td>
        </tr>
        <tr *ngFor="let item of mpdApi.queue; let i = index" (click)="mpdApi.play(i)" class="group hover:bg-base02 hover:text-base2" [ngClass]="{'text-base2 bg-base02 border-y-[1px] border-base0' : mpdApi.status?.songid == item.id}">
            <td class="pr-4 w-14 block">
                <app-audium-cover-art [filePath]="item.artist.toLowerCase()+'/'+item.album.toLowerCase()"></app-audium-cover-art>
            </td>
            <td class="pr-4">{{ item.title }}</td>
            <td class="pr-4">{{ item.artist }}</td>
            <td class="pr-4">{{ item.album }}</td>
            <td class=""></td> <!-- <span class="material-icons cursor-grab ivisible group:visible">clear</span> -->
        </tr>
    </table>
</div>
