import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http'
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  constructor (public authService: AuthService, private router: Router, private http: HttpClient) {}

  public isLoading: boolean = false;
  public userName: string = "";
  public password: string = "";
  public quotes: Array<any> = []
  public quote: any = {
    quote: "",
    author: "",
    book: ""
  };

  returnQuote() {
    function getRandomInt(min: number, max: number) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    return this.quotes[getRandomInt(0, this.quotes.length)]
  }

  ngOnInit(): void { 
    this.authService.isSignedIn()
    if (this.authService.isAuthed) {
      this.router.navigate(['dashboard'])
    }
    this.getQuotes()
  }

  getQuotes(): any {
    lastValueFrom(this.http.get('/api/directus/items/quotes'))
    .then((response: any) => {
      this.quotes = response.data
      this.quote = this.returnQuote()
    })
  }

  // onSignIn(form: NgForm){
  //   if (form.valid) {
  //     this.isLoading = true;
  //     this.authService.signIn(this.userName, this.password)
  //   }
  // }
  
}
