import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { lastValueFrom } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public router: Router, public http: HttpClient) {}
    public accessToken: string = ''
    private refreshToken: string = ''
    private userName: string = ''
    private jwt: any = {}
    private jwtHelper = new JwtHelperService();
    public expires: Date | null = null
    public isAuthed: boolean = false
    public errorMessage: string = ''

    signIn(userName: string, password: string) {
      this.errorMessage = ''
      this.userName = userName
      lastValueFrom(this.http.post('/api/directus/auth/login', 
      {email: userName, password: password, mode: 'cookie'},
      {observe: 'events', responseType: 'json', reportProgress: true}))
      .then((response: any) => {
        if (response.body.data.access_token) {
          this.setToken(response.body.data)
          this.router.navigate(['dashboard'])
        } else {
          console.error('No access token in response', response)
          this.errorMessage = 'No access token in response'
        }
      })
      .catch(err => {
        console.error(err.statusText, err)
        if (err.error.errors) {
            this.errorMessage = err.error.errors[0].message
        } else {
          this.errorMessage = err.statusText ? err.statusText : 'Unknown error. Check console for details.'
        }
      })
      .finally(() => {
        if (!this.isAuthed && this.errorMessage.length == 0) {
          this.errorMessage = 'Unknown error. Authentication server could not be reached.'
        }
      })
    }

    async isSignedIn(): Promise<boolean> {
      if (this.accessToken == "") {
        await this.refresh()
      }
      return this.isAuthed
    }

    async refresh() {
      await lastValueFrom(this.http.post('/api/directus/auth/refresh', { id: this.userName, mode: 'cookie'}, { withCredentials: true }))
        .then((response: any) => {
          this.setToken(response.data)
        })
        .catch(err => {
          console.log(err)
          // this.signOut()
        })
    }

    async signOut() {
      await lastValueFrom(this.http.post('/api/directus/auth/logout', {}, { withCredentials: true }))
      .then(response => {
        this.accessToken = ''
        this.isAuthed = false
        this.router.navigate(['signin'])
      })
      .catch(error => {
        console.log(error)
      })
    }

    setToken(data: any) {
      this.accessToken = data.access_token
      this.jwt = this.jwtHelper.decodeToken(this.accessToken)
      this.expires = this.jwtHelper.getTokenExpirationDate(this.accessToken)
      this.isAuthed = true
    }
}