import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuardService } from './auth/auth-guard.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { NotepadComponent } from './notepad/notepad.component';
import { TexteditorComponent } from './texteditor/texteditor.component';

import { QuillModule } from 'ngx-quill';

// import { AudiumComponent } from './audium/audium.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WeatherComponent } from './weather/weather.component';

import { AudiumModule } from './audium/audium.module';
import { SigninComponent } from './auth/signin/signin.component';
import { MachinesComponent } from './machines/machines.component';
import { DiabloComponent } from './diablo/diablo.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NotepadComponent,
    TexteditorComponent,
    // AudiumComponent,
    DashboardComponent,
    WeatherComponent,
    SigninComponent,
    MachinesComponent,
    DiabloComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    AudiumModule.forRoot(),
  ],
  providers: [AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
