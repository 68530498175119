import { Component, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TexteditorComponent } from '../texteditor/texteditor.component';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.css']
})
export class NotepadComponent {
  constructor(private noteEditor: TexteditorComponent, private http: HttpClient, private auth: AuthService, public formBuilder: FormBuilder, private cd: ChangeDetectorRef) { }
  private options: any = { headers: {Authorization: `Bearer ${this.auth.accessToken}`}}
  public categories = ['general', 'shopping', 'dev']
  public notes: any = []
  public newNote: any = {title: '', category: '', data: ''}
  public newNoteConfig = {holder: 'neweditor', id: 'neweditor', title: '', category: '', readOnly: false, data: {}}
  public newNoteForm = this.formBuilder.group({
    title: '',
    category: ''
  })
  public selectedNote: any = {}

  addItem(newItem: string) {
    this.notes.push({id: this.notes.length, title: 'Pushed', category: 'Pushed', body: newItem});
  }

  onClick(note: any) {
    this.selectedNote = note
    this.notes.find((n: any) => n.id == note.id).readOnly = false
    // this.notes = Array.from(this.notes)
    let tmp = [...this.notes]
    this.notes = null
    // this.notes = [...tmp]
    setTimeout(() => {
      this.notes = [...tmp]
      console.log('timeout')
    }, 2000);
    // this.notes = []
    this.cd.detectChanges();
    console.log('clicked')
  }

  setNote(note: any) {
    // TODO: set data in notes array
    // TODO: post/patch note
    // TODO: debounce
    console.log('set data', note)
    if (note.id == 'neweditor') {
      this.newNote = note
      // this.postNote(note)
    } else {
      // patch note by id
    }
  }

  postNote(note: any) {
    // title and category are being sent blank
    delete note.id
    this.http.post('/directus/items/notes', note, this.options)
    .subscribe((response: any) => {
      console.log('sub', response)
      this.notes.push(response.data)
    })
  }

  ngOnInit() {
    lastValueFrom(this.http.get('/api/directus/items/notes', this.options))
    .then((response: any) => {
      console.log(response)
      this.notes.push(...response.data.map((note: any) => {return {...note, readOnly: true}}))
    })

    setTimeout(() => {
      let newData = this.notes[0].data
      let newNote = {...this.notes[0]}
      newNote.id = 'test-new'
      this.notes[1].data = newData
      // this.notes.push(newNote)
      console.log('timeout', newData, this.notes[1].data, newData === this.notes[1].data, this.notes)
    }, 2000);
  }

}
