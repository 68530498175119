<app-nav></app-nav>
<router-outlet class=""></router-outlet>

<!-- <h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<p>This is a paragraph tag.</p>

<button class="btn">Button</button>
<div class="container">
  <div class="columns-2">
    <div class="row">
      <div class="swatch bg-orange"></div>
      <div>Orange</div>
    </div>
  </div>
</div> -->

<!-- 
  card
  nav
-->

<!-- <span class="material-symbols-outlined">home</span> -->