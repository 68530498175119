import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(!this.authService.isSignedIn()) {
      this.router.navigate(['signin'])
    }
    localStorage.setItem('portal-route', state.url) // hacky workaround to fix route on hot reload
    return this.authService.isAuthed;
  }
}

