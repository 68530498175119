import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Status, NowPlaying } from './mpd-api.interface';

import thumbnails from './thumbnails.json'

@Injectable({
  providedIn: 'root'
})
export class MpdApiService {
  constructor(private http: HttpClient) { }

  public client = null
  public status: Status | undefined;
  public nowPlaying: NowPlaying | null = null
  public library: any = null
  public queue: any = null
  public statusInterval: any = null
  public view: string = "queue"
  public thumbnails = thumbnails

  private rootURL = '/api/audium';

  update() {
    this.currentSong()
    this.getStatus()

    let seekbar = document.getElementById('seekbar')
    if (seekbar !== null && this.status) {
      seekbar.style.width = `${Math.round((this.status.elapsed/this.status.duration)*100)}%`
    }
  }

  resume() {
    return this.http.get(this.rootURL + '/resume')
    .subscribe(res => {
          this.update()
    });
  }

  pause() {
    return this.http.get(this.rootURL + '/pause')
    .subscribe(res => {
        this.update()
        clearInterval(this.statusInterval)
        this.statusInterval = null
    });
  }

  play(item: any) {
    this.http.post(this.rootURL + '/play', {index: item})
    .subscribe(res => {
        this.update()
    });
  }

  add(item: any) {
    this.http.post(this.rootURL + '/add', {file: item})
    .subscribe(res => {
        this.update()
        this.getQueue()
    });
  }

  remove(item: any) {
    this.http.post(this.rootURL + '/remove', {file: item})
    .subscribe(res => {
        this.update()
        this.getQueue()
    });
  }

  clear() {
    this.http.post(this.rootURL + '/clear', null)
    .subscribe(res => {
        this.update()
        this.getQueue()
    });
  }

  previous() {
    return this.http.get(this.rootURL + '/previous')
    .subscribe(res => {
        this.update()
    });
  }

  next() {
    return this.http.get(this.rootURL + '/next')
    .subscribe(res => {
      this.update()
    });
  }

  getStatus() {
    this.http.get<Status>(this.rootURL + '/status')
    .subscribe(res => {
        this.status = res
        if (this.status.state == 'play' && !this.statusInterval) {
          this.statusInterval = setInterval(() => {
            this.update()
          }, 1000)
        } else if (this.status.state == 'pause') {
          clearInterval(this.statusInterval)
          this.statusInterval = null
        }
    });
    return this.status
  }

  currentSong() {
    this.http.get<NowPlaying>(this.rootURL + '/current-song')
    .subscribe(res => {
      this.nowPlaying = {...res, ...this.queue.find((q: any) => q.file == this.nowPlaying?.file)}
      console.log(this.nowPlaying?.artist?.toLowerCase()+'/'+this.nowPlaying?.album?.toLowerCase())
    });
  }

  getLibrary() {
    this.http.get(this.rootURL + '/library')
    .subscribe(res => {
        this.library = res
    });
  }

  getQueue() {
    this.http.get(this.rootURL + '/queue')
    .subscribe(res => {
        this.queue = res
    });
  }

}