import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth/auth-guard.service';
import { SigninComponent } from './auth/signin/signin.component';

import { NotepadComponent } from './notepad/notepad.component'
import { AudiumComponent, AudiumLibraryComponent, AudiumQueueComponent } from './audium/audium.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { WeatherComponent } from './weather/weather.component'
import { MachinesComponent } from './machines/machines.component';
import { DiabloComponent } from './diablo/diablo.component';

const routes: Routes = [
  {path: 'signin', component: SigninComponent},
  {path: 'notepad', component: NotepadComponent, canActivate: [AuthGuardService]},
  {path: 'audium', component: AudiumComponent, canActivate: [AuthGuardService], children: [
    {path: 'library', component: AudiumLibraryComponent, canActivate: [AuthGuardService]},
    {path: 'queue', component: AudiumQueueComponent, canActivate: [AuthGuardService]},
  ]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'weather', component: WeatherComponent, canActivate: [AuthGuardService]},
  {path: 'machines', component: MachinesComponent, canActivate: [AuthGuardService]},
  {path: 'diablo', component: DiabloComponent, canActivate: [AuthGuardService]},
  { path: '**', component: DashboardComponent, canActivate: [AuthGuardService] }, // PageNotFoundComponent
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule,],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }
