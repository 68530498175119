  
<div class="flex flex-col items-center">
    <div *ngIf="this.quotes.length > 0" class="flex flex-col mt-2 items-center">
        <div><span class="text-base2 text-[1.25em] mr-1">“</span><span class="italic">{{ quote.quote }}</span><span class="text-base2 text-[1.25em] ml-1">”</span></div>
        <div class="text-base0">{{ quote.author }} in <cite title="Source Title">{{ quote.book }}</cite></div>
    </div>

    <form class="form flex flex-col mt-4" (submit)="authService.signIn(userName, password)" #signInForm="ngForm">
            <label class="text-base1" for="email">Username</label><br>
            <input [(ngModel)]="userName" class="bg-base3 rounded-sm text-black" name="email" type="email"  placeholder="" required>
            <label class="text-base1 mt-2" for="password">Password</label><br>
            <input [(ngModel)]="password" class="bg-base3 rounded-sm text-black" type="password" minlength="8"  required name="password" placeholder="" >
            <button class="btn mt-4" type="submit">Sign in</button>
            <p class="text-base2">{{ authService.errorMessage }}</p>
    </form>
</div>
