import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})
export class MachinesComponent {
  constructor(private http: HttpClient, private auth: AuthService) {}
  public machines: any = []

  public routeService(url: string) {
    window.location.href = url
  }

  ngOnInit() {
    this.http.get('/directus/items/machines', { headers: {Authorization: `Bearer ${this.auth.accessToken}`}})
    .subscribe((response: any) => {
      this.machines = response.data
    })
    
  }
}
