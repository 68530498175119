<div class="width-full" *ngIf="authService.isAuthed">
    <div class="flex flex-row bg-base02">
        <div class="flex flex-grow shadow-md">
            <div class="w-1/2 flex flex-row">
                <div *ngFor="let menu of menus"
                [routerLink]="menu.link"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-base3"
                [class]="menu.class"
                class="group hover:text-base3 align-bottom hover:base-03 px-2"
                >
                    <span class="align-text-top">{{ menu.title }}</span>
                    <div class="invisible z-10 flex absolute flex-col bg-base03 translate-x-[-8px]">
                        <div class="group-hover:visible border-[0px] rounded-md shadow-md border-base03 bg-base02">
                            <div *ngFor="let submenu of menu.submenu"
                            [routerLink]="submenu.link"
                            [routerLinkActiveOptions]="{ exact: true }"
                            routerLinkActive="text-base3"
                            class="hover:bg-base03 text-base0 hover:text-base2 first:rounded-t-md last:rounded-b-md px-2">{{ submenu.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row-reverse w-1/2 gap-2">
                <span class="align-text-top">{{dateString}} {{timeString}}</span>
                <span class="">23c</span>
                <span (click)="authService.signOut()" class="bg-base03 rounded-full text-xs p-[0.15rem] my-[0.2rem] border-base01 border-[1px]">MR</span>
                <span class="material-icons text-[1.25rem] my-auto">notifications</span>
                <span class="material-icons text-[1.25rem] my-auto">search</span>
            </div>
        </div>
    </div>
</div>
