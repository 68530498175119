<!-- Library (list) -->
<table class="w-[100%]">
    <ng-container *ngFor="let artist of mpdApi.library">
        <tr class="hover:bg-base02 hover:text-base2 group whitespace-nowrap">
            <td (click)="selectedArtist == artist? selectedArtist = null : selectedArtist = artist"><app-audium-cover-art [filePath]="artist.artist.toLowerCase()"></app-audium-cover-art></td>
            <td class="pl-2" (click)="selectedArtist == artist? selectedArtist = null : selectedArtist = artist">{{ artist.artist}}</td>
            <td class="overflow-hidden">
                <span *ngFor="let album of artist.albums" (click)="add(album.directory, album.title, album.date)">
                    <app-audium-cover-art style="display:inline" [filePath]="artist.artist.toLowerCase()+'/'+album.title.toLowerCase()"></app-audium-cover-art><span class="px-2">{{ album.title }}</span>
                </span>
            </td>
        </tr>
        <ng-container *ngIf="selectedArtist == artist">
            <tr *ngFor="let album of artist.albums" class="hover:bg-base02 hover:text-base2 group">
                <td class="pl-4"></td>
                <td class="pl-4"><app-audium-cover-art [filePath]="album.tracks[0].file || ''"></app-audium-cover-art></td>
                <td class="pl-8">{{ album.title }}</td>
                <td class="pl-4">{{ dateString(album.date) }}</td>
                <td class="text-base03 group-hover:text-base2">
                    <span (click)="mpdApi.clear();add(album.directory, album.title, album.date)"class="material-icons align-middle pl-2">play_circle_filled</span>
                    <span (click)="add(album.directory, album.title, album.date)" class="material-icons align-middle pl-2">add_circle</span>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</table>