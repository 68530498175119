import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public router: Router) {}
  title = 'mjr-portal';
  
  ngOnInit() { // this whole init block is a hacky workaround to fix route on hot reload
    let previousRoute = localStorage.getItem('portal-route')
    if (previousRoute) {
      console.log('routing to', previousRoute)
      setTimeout(() => {this.router.navigate([previousRoute])}, 200)
    }
  }
}
