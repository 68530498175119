import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) { }
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {
  constructor(public authService: AuthService) {}

  // variables
  public date: Date = new Date();
  public dateString: String = '';
  public timeString: String = '';
  public weatherJson: Object = {};
  private twClasses = "text-[1.25rem] my-auto"
  public menus: Array<any> = [
    {title: 'home', link: '/dashboard', class: `material-icons ${this.twClasses}`},
    {title: 'Audium', link: '/audium'},
    {title: 'Notepad', link: '/notepad'},
    {title: 'Weather', link: '/weather'},
    {title: 'Docs', link: '/docs', submenu: [
      {title: 'Wiki', link: '/docs/wiki'}
    ]},
    {title: 'Library', link: '/library',
    submenu: [
      {title: 'Music', link: '/library/music'}, 
      {title: 'Series', link: '/library/series'},
      {title: 'Movies', link: '/library/movies'},
      {title: 'Books', link: '/library/books'},
    ]},
    {title: 'Tools', link: '/tools', submenu: [
      {title: 'Invoices', link: '/invoices'}, 
      {title: 'Beer Journal', link: '/journal/beer'},
      {title: 'Wine Journal', link: '/journal/wine'},
      {title: 'Whiskey Journal', link: '/journal/whisky'},
      {title: 'Recipes', link: '/journal/recipes'}
    ]},
    {title: 'Machines', link: '/machines'},
    {title: 'Diablo', link: '/diablo'},
  ]

  private dateInterval: any;

  // getters and setters
  getTime() {
    const locale = 'en-AU';
    const options: Intl.DateTimeFormatOptions = {hour: 'numeric', minute: 'numeric', hour12: true};
    return this.date.toLocaleTimeString(locale, options);
  }
  
  getDate() {
    const locale = 'en-AU';
    const options: Intl.DateTimeFormatOptions = {weekday: 'short', day: 'numeric', month: 'short'};
    return this.date.toLocaleDateString(locale, options).replace(',','');
  }
  
  setDate() {
    this.date = new Date();
    this.dateString = this.getDate();
    this.timeString = this.getTime();
  }

  // getBomData() {
  //   HttpClient.get('http://reg.bom.gov.au/fwo/IDQ60901/IDQ60901.94576.json', (resp) => {
  //       let data = '';

  //       resp.on('data', (chunk) => {
  //           data += chunk;
  //       });

  //       resp.on('end', () => {
  //           console.log('Fetched latest observation data.')
  //           this.weatherJson = JSON.parse(data);
  //           this.weatherJson.observations.data[0].air_temp_round = Math.round(parseInt(this.weatherJson.observations.data[0].air_temp));
  //       });
  //   }).on("error", (err) => {
  //   console.log("Error: " + err.message);
  //   });
  // };

  // angular lifecycle hooks
  ngOnInit() {
    this.dateInterval = setInterval(this.setDate.bind(this), 1000)
  }

  ngOnDestroy() {
    clearInterval(this.dateInterval)
  }
}
